.h2 {
  @apply text-xl font-bold my-4;
}

@layer utilities {
  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

// headers

/* global styling for dato cms structured text */
.prose {
  h1,
  h2,
  h3,
  h1 *,
  h2 *,
  h3 * {
    @apply text-black;
  }

  h1 {
    font-size: 1.75em;
  }

  h2 {
    margin-top: 1em;
  }

  h4,
  h4 * {
    @apply text-gray-600;
  }
}

.min-h-inv {
  min-height: calc(100vh - 221px);
}

.hubspotFormStyling {
  @apply mt-5;

  .field {
    @apply mb-4 mt-6;
  }
  
  .input {
    @apply mb-1 mt-2 flex rounded-3xl bg-white py-2.5 pr-2.5 shadow-xl shadow-blue-900/5 focus-within:ring-2 focus-within:ring-blue-900;

    input {
      @apply -my-2.5 flex-auto border-none bg-transparent p-4 pl-6 pr-2.5 text-base text-slate-900 placeholder:text-slate-400 focus:ring-0 active:ring-0;
    }
  }
  
  .hs-error-msg {
    @apply mt-3 block rounded-md bg-error px-2 font-bold text-white;
  }
  
  .actions {
    @apply flex justify-end;
  }
  
  .hs-button.primary {
    @apply inline-flex justify-center rounded-2xl bg-primary p-4 px-10 text-base font-semibold text-white hover:bg-primary focus:outline-none focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary active:text-white/70;
  }
  
  .hs-form-required {
    @apply pl-1 text-primary-turquoise;
  }
  
  .submitted-message {
    @apply rounded-md bg-white p-5  border-4;
  }
}

@tailwind base;
@tailwind components;
@tailwind utilities;
