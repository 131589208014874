.slick-dots {
  position: relative !important;

  li {
    height: auto !important;
    width: 15px !important;
  }

  .slick-active {
    background-color: #e5e7e9;
    border: 1px transparent;
    border-radius: 50%;
  }
}
.ft-slick__dots--custom {
  height: 15px;
  width: 15px;
  border: 1px solid white;
  border-radius: 50%;
}

.loading {
  height: 8px;
  animation: loading 5s ease-in;
  background-image: linear-gradient(270deg, #4fd15a, #03ac0e);
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 4px;
}
.company-slider .slick-list,
.company-slider .slick-track {
  height: 100%;
  padding: 0 !important;
}

.home-slider .slick-list .slick-track {
  display: flex;
  align-items: center;
  text-align: center;
}
/* Home companies list css */
.swiper-container {
  height: calc(100vh - 120px);
  margin: 60px;
}

.swiper-slide {
  width: 28vw;
  overflow: hidden;
  background-position: top;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 20px;
  font-weight: bold;
}

.swiper-wrapper {
  -webkit-transition-timing-function: linear !important;
  -o-transition-timing-function: linear !important;
  transition-timing-function: linear !important;
  align-items: center;
}
